import "./App.css";

function App() {
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+ywAoY0DnbDdhYzI1", "_blank");
    // window.open("https://t.me/your-telegram-channel", "_blank");
  };

  return (
    <div className="App" onClick={isMobileDevice() ? openTelegram : null}>
      <div className="box">
        <h2
          style={{
            color: "#F7444E",
            fontSize: "2.2rem",
            padding: 0,
            margin: 0,
            marginBottom: "0.6rem",
          }}
        >
          𝗩𝗜𝗞𝗥𝗔𝗡𝗧 𝗕𝗛𝗔𝗜
        </h2>
        <img
          src="vikrantbhai.jpg"
          style={{
            height: "10rem",
            width: "10rem",
            borderRadius: "50%",
            marginBottom: "1.7rem",
            border: "2px solid #002C3E",
          }}
          alt="Logo"
        />
        <button
          class="button-85"
          onClick={isMobileDevice() ? openTelegram : null}
        >
          <div
            className="button"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <img
                src="logo.webp"
                style={{
                  height: "1.8rem",
                  width: "1.8rem",
                  display: "inline",
                }}
                alt="Logo"
              />
            </div>
            <div style={{ fontWeight: "bolder", fontSize: "1.5rem" }}>
              &nbsp;&nbsp;JOIN TELEGRAM
            </div>
          </div>
        </button>
        <div className='divPara'>
          <p>𝘑𝘖𝘐𝘕 𝘒𝘙𝘒𝘌 𝘊𝘏𝘌𝘊𝘒 𝘒𝘙𝘖 𝘍𝘐𝘙 𝘉𝘖𝘓𝘖𝘎𝘌</p>
          <p>𝘒𝘈𝘈𝘚𝘏 𝘗𝘈𝘏𝘓𝘌 𝘔𝘐𝘓 𝘑𝘈𝘛𝘈 𝘓𝘐𝘕𝘒 🏆 </p>
          <p>𝘛𝘐𝘗𝘗𝘐𝘕𝘎 𝘒𝘈 𝘉𝘈𝘈𝘗 𝘏𝘜 𝘉𝘈𝘈𝘗 💸</p>
          <p>𝘉𝘌𝘚𝘛 𝘛𝘐𝘗𝘗𝘌𝘙 𝘖𝘕 𝘌𝘈𝘙𝘛𝘏 👑</p>
        </div>
        <p style={{ marginBottom:'0.7rem' }}>👇 100% FIXED IPL REPORTS 👇</p>
        <p
          style={{
            padding: "1.3rem",
            borderRadius: "60px",
            fontFamily: "Arial Narrow Bold, sans-serif",
            fontWeight: "bolder",
            fontSize: "1.5rem",
            marginTop: "1.2rem",
            background: "#002C3E",
            color: "#F7F8F3",
          }}
        >
          INDIA' S BEST TIPPER
        </p>
      </div>
    </div>
  );
}

export default App;
